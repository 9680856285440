<template>
  <div class="view-admin-guidance-topic">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div class="page-header" v-if="!isLoadingCombined">
      <h1 class="page-title">Topic: {{ topic["$v"]["displayName"] }}</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type=""
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-button
          class="button-margin-left btn-rounded"
          icon="edit"
          size="large"
          type="primary"
          @click.prevent="edit"
          v-if="canEdit"
          >Edit</a-button
        >

        <a-popconfirm
        v-if="canEdit"
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>

    <!-- Tabs -->
    <div v-if="!isLoadingCombined">
      <!-- <a-tabs v-model="selectedTab">
        <a-tab-pane tab="Articles" key="articles">
            <topic-articles
             :topic="topic" :tenant-id="tenantId"
             :is-loading="isLoadingContentLinks"
             :content-links="contentLinks"
             @reload="loadContentLinks"
             ></topic-articles>
        </a-tab-pane>
      </a-tabs> -->

      <content-link-tabs
        :tenant-id="tenantId"
        :org-id="selectedOrganisation.id"
        :parent-id="topic['$v'].id"
        parent-type="Topic"
        :content-links="contentLinks"
        :is-loading="isLoadingContentLinks"
        :types="['articles']"
        :can-create="['articles']"
        :can-edit="canEdit"
        @refresh="loadContentLinks"
      >
      </content-link-tabs>
    </div>
    <!-- / Tabs -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// const _ = require("lodash");
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import topics from "../../../../api/guidance/topics";
// import TopicArticles from "./Tabs/TopicArticles.vue";
import contentLinks from "../../../../api/guidance/content-links";
import ContentLinkTabs from "../../../../components/Admin/Guidance/ContentLinkTabs.vue";

export default {
  components: { LoadingScreen, ContentLinkTabs },

  data() {
    return {
      isLoadingTopic: true,
      topic: null,

      isDeleting: false,

      isLoadingContentLinks: false,
      contentLinks: [],
    };
  },

  created() {
    // if (!this.topic) {
    //   this.$message.info("Topic not found");
    //   this.$router.push("/admin/guidance/topics");
    // }
    this.loadTopic();
    this.loadContentLinks();
  },

  methods: {
    goBack() {
      if (this.$route.query.backTo) {
        this.$router.push(this.$route.query.backTo);
      } else {
        this.$router.push("/admin/guidance/topics");
      }
    },

    edit() {
      this.$router.push("/admin/guidance/topics/" + this.topicId + "/edit");
    },

    loadTopic() {
      let vm = this;
      vm.isLoadingTopic = true;
      topics
        .getTopic(this.tenantId, this.topicId)
        .then((r) => {
          vm.isLoadingTopic = false;
          vm.topic = r.data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.info("Topic not found");
          this.$router.push("/admin/guidance/topics");
        });
    },

    loadContentLinks() {
      let vm = this;
      vm.isLoadingContentLinks = true;
      contentLinks
        .getContentLinksForOrgByParentEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.topicId
        )
        .then((r) => {
          vm.isLoadingContentLinks = false;
          vm.contentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingContentLinks = false;
        });
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      topics
        .deleteTopic(vm.tenantId, vm.topicId)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Topic deleted successfully");
          vm.$router.push("/admin/guidance/topics");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
          vm.$message.error("Error deleting topic");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("missionControl", {
      idsOfOrganisationsIAmAnAdminFor: "idsOfOrganisationsIAmAnAdminFor"
    }),

    topicId() {
      return this.$route.params.id;
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingTopic;
    },

    canEdit() {
      if (!this.topic) {
        return false;
      }
      return this.idsOfOrganisationsIAmAnAdminFor.includes(this.topic['$v']['ownerId'])
    }
  },
};
</script>

<style lang="scss">
.view-admin-guidance-topic {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .button-margin-left {
    margin-left: 10px;
  }
}
</style>